.to-delete * {
  text-decoration: line-through;
}

.row-to-delete {
  //  opacity: 0.6;
}

.row-to-delete {
  * {
    text-decoration: line-through;
    color: #bfbbbc !important;
  }
}
