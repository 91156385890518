:root {
    --range-selected: var(--tangerine);
    --range-select-hover-border: var(--tangerine);
    --range-semi-selected: var(--dirty-purple-8);
    --range-semi-hover: var(--dirty-purple-8);
    --range-semi-hover-border: var(--dirty-purple-55);
  }
  
  .mat-datepicker-content {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  }
  
  :not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
    // background-color: var(--range-semi-hover);
    border-color: var(--dirty-purple-8);
    color: var(--dirty-purple);
  }
  
  .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
    border-radius: 100% 0 0 100%;
    background-color: var(--range-semi-selected);
  }
  
  .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    border-radius: 0 100% 100% 0;
    background-color: var(--range-semi-selected);
  }
  
  .mat-calendar-body > tr .mat-calendar-cell-semi-selected ~ .mat-calendar-cell-semi-selected {
    border-radius: 0;
  }
  
  .mat-calendar-body-in-range::before {
    background: var(--range-semi-selected);
  }
  
  .mat-calendar-cell-semi-selected {
    background-color: #c5cae9;
  }
  
  .mat-calendar-cell-over > .mat-calendar-body-cell-content {
    border: 1px solid;
    border-color: var(--range-select-hover-border);
  }
  
  .mat-calendar-table-header th {
    text-align: center;
    padding: 0 0 8px;
  }
  
  .mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400;
  }
  
  
  .mat-calendar thead th {
    background-color: var(--white);
  }
  
  .mat-datepicker-toggle {
    display: flex;
  }
  
  .mat-datepicker-toggle button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mat-calendar-body-cell-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    line-height: 1;
    border-width: 1px;
    border-style: solid;
    border-radius: 999px;
  }
  
  .mat-calendar-body-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    cursor: pointer;
  }
  
  .sat-datepicker-content,
  .mat-datepicker-content {
    background: var(--white);
  }
  
  .mat-calendar-header {
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .mat-calendar-controls {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  
  .mat-calendar-body-label {
    font-size: 14px;
    font-weight: 500;
    height: 0;
    line-height: 0;
    text-align: left;
    color: rgba(0,0,0,.54);
    /*height: 42px !important;*/
  }
  
  .mat-calendar-controls button {
    background: transparent;
  }
  
  .mat-calendar-body {
    font-size: 13px;
  }
  
  
  .mat-calendar-body-cell-content {
    color: rgba(0,0,0,.87);
    border-color: transparent;
  }
  
  .mat-calendar .mat-calendar-header {
    padding-top: 0;
  }
  
  .mat-calendar-body-selected {
    background-color: var(--range-selected);
    color: #fff;
  }
  
  .mat-calendar-cell-semi-selected {
    background-color: var(--range-semi-selected);
  }
  
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    background-color: var(--dirty-purple-8);
  }

  .mat-calendar-body-disabled .mat-calendar-body-cell-content {
    opacity: 0.25;
  }

  .mat-calendar-body-disabled {

    &:not(.mat-calendar-body-disabled) {
      .mat-calendar-body-cell-content {
        background: var(--light-pink);
        opacity: 0.5;
      }
    }
}