.mat-tab-links {
  // gap: 30px;
  gap: 3px;

  .mat-tab-link {
    min-width: unset !important;
    color: var(--grey-100, #5e5357);
    font-weight: 700;
    line-height: 1.125rem;
    font-size: 0.875rem;
    padding: var(--5x) 0.9375rem !important;
    gap: var(--3x) !important;
    border-bottom: 3px solid transparent;
    border-radius: 1.5px;
    font-family: "RedHatDisplay";
    height: 2.4375rem;
    opacity: 1;

    &.mat-tab-label-active {
      border-color: var(--tangerine);
      opacity: 1;
    }
  }
}

.mat-tab-label {
  .mat-tab-label-content {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-size: inherit;
    font-family: inherit;
  }

}

