.green-button,
.beige-button,
.orange-button,
.black-button,
.grey-button,
.greyed-button,
.transparent-button,
.date-period-button {
  display: flex;
  justify-content: center;
  height: var(--button-height);
  padding: var(--button-padding);
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: RedHatText;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  background-repeat: no-repeat;
}



lxm-button {

  &.loading {
    button {
      svg-icon {
        opacity: 0;
      }
    }
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;

  lxm-button {
    min-width: 60px;

    button {

      height: var(--button-height) !important;
      border: none !important;
      border-bottom: 2px solid var(--pale-pink) !important; 
      border-radius: 0 !important;
      padding: 6.5px 10px !important;

      .lxm-button-text {
        font-family: RedHatDisplay;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dirty-purple);
        opacity: 0.8;
      }
    }

    &.active {
      button {
        border-bottom: 2px solid var(--tangerine) !important;
        .lxm-button-text {
          font-size: 16px;
          opacity: 1;
          font-weight: bold;
        }
        
        &:hover {
          opacity: 1 !important;
        }
      }
    }



  }

  // lxm-button button {
  //   // border: 1px solid #ebe9ea !important;
  // }

  // lxm-button:not(:first-child) button {
  //   border-top-left-radius: 0 !important;
  //   border-bottom-left-radius: 0 !important;
  //   border-left: 0 !important;
  // }

  // lxm-button:not(:last-child) button {
  //   border-top-right-radius: 0 !important;
  //   border-bottom-right-radius: 0 !important;
  //   border-right: 0 !important;
  // }

}

.green-button {
  background: linear-gradient(112deg, rgba(24, 163, 73, 0.15) 11%, rgba(154, 206, 129, 0.15) 91%);
  color: var(--dirty-purple);
  white-space: nowrap;
}

.beige-button {
  background: linear-gradient(145deg, rgba(240, 75, 84, 0.15) -19%, rgba(247, 146, 30, 0.15) 57%);
  color: var(--dirty-purple);
}

.orange-button {
  background-image: linear-gradient(155deg, var(--watermelon) -29%, var(--tangerine) 74%);
  color: var(--white);
}

.grey-button {
  /*background: var(--dirty-purple-8);*/
  color: var(--dirty-purple);
}

.black-button {
  background: var(--dirty-purple);
  color: var(--white);
}

.transparent-button {
  background: transparent;
  color: var(--dirty-purple);
}

.greyed-button {
  border: solid 1px var(--dirty-purple-15);
  color: var(--dirty-purple-55);
}

.toggle-button, .date-period-button {
  background: transparent;
}

.date-period-button.active {
  border: solid 1px var(--dirty-purple-15);
  background: var(--dirty-purple);
  color: var(--white);
}


.button-icon-front {
  margin-right: 8px;
}


.green-button:disabled,
.beige-button:disabled,
.orange-button:disabled,
.black-button:disabled,
.grey-button:disabled,
.greyed-button:disabled,
.transparent-button:disabled,
.date-period-button:disabled {
  cursor: default;
  opacity: 0.7;
}

a {
  color: var(--dirty-purple);
}