/** 
Global notification styles
Required to work with innerHTML
**/

.notification-card-header {

    .notification-header-subject {
        font-size: 18px !important;
        a {
            text-decoration: none !important;
        }
    }

    .notification-header-notifier {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dirty-purple);
    }
    
}

