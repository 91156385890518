.mat-checkbox {
  

  &.loading {
    pointer-events: none;
    .mat-checkbox-inner-container {
      animation: spin 1s infinite linear;
      animation-delay: 1s;
    }
    .mat-checkbox-frame {
      animation: startloading 0.5s linear;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }

}

.mat-checkbox-label {
  color: var(--dirty-purple);
  font-size: 14px;
}

@keyframes startloading {
  0% {
    border-radius: 0%;
  }
  100% {
    border-radius: 50%;
    border-right: 1px solid var(--tangerine);
  }
}

input.mat-checkbox-input {
  height: 100%;
  width: 100%;
}

.mat-checkbox-frame {
  border-radius: 3px;
  border: solid 1px rgba(94, 83, 87, 0.24);
}

input.mat-checkbox-input {
  opacity: 0;
  cursor: pointer;
}

.mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  color: black;
}

.readonly {
  .mat-checkbox,
  lxm-checkbox {
    cursor: default;
    user-select: none;
    pointer-events: none;
  }
}

.mat-checkbox-disabled .mat-checkbox-frame,
.mat-checkbox.disabled .mat-checkbox-frame {
  border-color: transparent;
}

/*.mat-checkbox-checked .mat-checkbox-background {
  background-color: white;
  border: 1px solid black;
}*/

/*.mat-checkbox-background {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}*/

/*.mat-checkbox-checkmark {
  background-image: url("/assets/img/icons/icon-accept.svg") !important;
  width: 10px !important;
  height: 10px !important;
  stroke: var(--dirty-purple);
  fill: var(--dirty-purple);
  object-fit: contain;
  background-size: cover;
  position:relative !important;
  font-weight: bold;
}

.mat-checkbox-mixedmark {
  display:none;
}

.mat-checkbox-checkmark-path {
  display: none;
}*/

.mat-checkbox-checkmark-path {
  stroke: var(--dirty-purple);
}
