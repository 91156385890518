/* CARD */

.card {
  display: flex;
  flex: 1;
  border-radius: 3px;
  box-shadow: var(--card-box-shadow);
  background-color: var(--card-background);
  padding: var(--card-padding);
  width: 100%;
  position: relative;

  &.full-screen {
    position: fixed !important;
    height: 100% !important;
    z-index: 206;
    right: 0;
    top: 0;

    width: calc(100% - var(--navigator-width) - 2px);

    &.nav-collapsed {
      width: calc(100% - var(--navigator-collapsed-width) - 2px);
    }

    .card-content {
      overflow: auto !important;
    }
  }

  &.aligned {
    .card-content {
      margin: var(--card-content-aligned-margin);
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  }

  .card-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: var(--card-header-padding-left);
    padding-right: var(--card-header-padding-right);
    min-height: var(--button-height);
    // margin-top: -8px;

    gap: 15px;

    .card-title {
      margin-left: calc(var(--card-header-padding-left) * -1 + 5px);
      // margin-right: 30px;
    }

    // [header-title-after] {
    //   margin-right: 30px;
    // }
  }

  .card-content-header {
    font-family: RedHatDisplay;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: var(--dirty-purple);
  }

  .card-title,
  [header-title] {
    display: flex;
    font-family: RedHatDisplay;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: var(--dirty-purple);
    font-weight: 700;
    align-items: center;
  }

  [header-actions] {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    // align-items: flex-start;
    // justify-content: flex-end;
    // height: 100%;

    gap: 10px 15px;
    font-size: 14px;
    font-weight: 500;

    // lxm-button,
    // lxm-select,
    // owner-select,
    // lxm-input,
    // combined-toggle-button,
    // dot-menu,
    // form-field .field-label,
    // .selected-products {
    //   margin-top: 10px;
    // }

    a.card-header-action {
      // margin-top: 15px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  [header-actions] a {
    color: var(--rusty-orange);
    background: none;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
  }

  .card-header-button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .card-header-title-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    // margin-top: 10px;
    // margin-right: 5px;
    flex: 1;
    gap: 15px;

    lxm-breadcrumb {
      position: absolute;
    }
  }

  .card-header-actions-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    flex-basis: fit-content;
  }

  .card-content {
    position: relative;
    display: flex;
    flex: 1;
    max-width: 100%;
    overflow: hidden; // !testing

    &:not(:empty) {
      margin: var(--card-content-margin);
      margin-top: var(--card-content-margin-top);
    }

    [body] {
      // overflow: auto;
      width: 100%;

      > :first-child {
        margin-top: 0;
      }
    }

    [tab-group] {
      display: flex;
      flex: 1;
      padding: 0 10px;
    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    flex: 0;
    padding: 0 10px;
    margin-top: 10px;

    &:empty {
      display: none;
    }

    [footer] {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
}

.active .card {
  z-index: var(--locker-active-card-z-index);
}

card-section {
  width: 100%;
}

.card-content .mat-tab-label,
.card-content .mat-tab-link {
  min-width: unset !important;
  padding: 0 !important;
  font-family: RedHatText;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  font-size: 16px;
  font-stretch: normal;
  // color: var(--dirty-purple);
  border-color: transparent !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0125rem;
  color: var(--dirty-purple);
}

.card-content .mat-tab-label.mat-tab-label-active,
.card-content .mat-tab-link.mat-tab-label-active {
  font-weight: 500;
  opacity: 1 !important;
}

.card-content .mat-tab-label:not(:first-of-type),
.card-content .mat-tab-link:not(:first-of-type) {
  margin-left: 30px;
}

.card-content .mat-tab-body {
  margin-top: 20px;
  padding: 0 20px;
}

.status-circle-container {
  display: flex;
  align-items: center;
  margin-right: 6px;
  height: 8px;
  width: 8px;
}

/* CARD EXPANDING */

.expansion-card-toggle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.section-expanding-content [body] {
  display: flex;
  flex: 1;
  // overflow-y: hidden;
}
