/* red-hat-display-regular - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display'), local('RedHatDisplay-Regular'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-regular.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-italic - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display It'), local('RedHatDisplay-Italic'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-500 - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Medium'), local('RedHatDisplay-Medium'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-500italic - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Medium It'), local('RedHatDisplay-MediumItalic'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-500italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-700 - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Bold'), local('RedHatDisplay-Bold'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-700italic - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Bold It'), local('RedHatDisplay-BoldItalic'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-700italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-900 - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Black'), local('RedHatDisplay-Black'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
/* red-hat-display-900italic - latin */
@font-face {
  font-family: 'RedHatDisplay';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Display Black It'), local('RedHatDisplay-BlackItalic'), url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatDisplay/red-hat-display-v3-latin-900italic.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
