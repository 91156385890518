.mat-select:not(.navigator-menu-select) {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  /*display: flex;
  flex-flow: column wrap;
  border: solid 1px rgba(94, 83, 87, 0.25);
  border-radius: 3px;
  background-color: var(--white);
  outline: none;
  padding: 8px 10px;
  min-height: var(--input-min-height);
  overflow: hidden;*/
}

.mat-option {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.mat-select-panel {
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.02), 0 4px 5px 0 rgba(0, 0, 0, 0), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.mat-menu-panel {
  min-height: 44px !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0,0,0,0.06);
}

.mat-checkbox.has-additional-field {
  .mat-checkbox-layout .mat-checkbox-label {
    // line-height: normal;
    line-height: 18px;
  }
}

/* STANDARD HTML SELECT (SHOULD NOT BE USED)*/

select {
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: solid 1px rgba(94, 83, 87, 0.25);
  background-color: var(--white);
  outline: none;
  height: 36px;
  background: url("/assets/img/icons/arrow-small.svg") no-repeat right center #fff;
  -webkit-appearance: none;
  /*background-position-x: right;*/
  background-origin: content-box;
  /*padding: 0 0.5em;*/
  cursor: pointer;
  /*padding: 10px;*/
  padding: 8px 10px;
  font-family: RedHatText;
  /*font-size: 14px;*/
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
}

