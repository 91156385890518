/*@import '@angular/material/prebuilt-themes/deeppurple-amber.css';*/
@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~quill/dist/quill.snow.css";

// FONTS
@import "./assets/fonts/RedHatDisplay/RedHatDisplay.css";
@import "./assets/fonts/RedHatText/RedHatText.css";
@import "./assets/fonts/NotoSans/NotoSans.css";

// NORMALIZE CSS
@import "./app/styles/normalize.scss";

// VARIABLES
@import "./app/styles/colors.scss";
@import "./app/styles/_config.scss";

// GLOBAL
@import "./app/app.component.scss";
@import "./app/components/_layout/navigator/css/navigator.global.scss";
@import "./app/styles/flex.scss";
@import "./app/styles/misc.scss";
@import "./app/styles/text.scss";
@import "./app/styles/responsive.scss";
@import "./app/styles/buttons.scss";
@import "./app/styles/fields.scss";
@import "./app/styles/dialogs.scss";
@import "./app/styles/icons.scss";
@import "./app/styles/tables.scss";
@import "./app/styles/select.scss";
@import "./app/styles/layout.scss";
@import "./app/styles/search-panel.scss";
@import "./app/styles/readonly.scss";
@import "./app/styles/to-delete.scss";
@import "./app/styles/notifications.scss";
@import "./app/styles/popper.scss";
@import "./app/styles/product-replacement.scss";
@import "./app/styles/tags.scss";

@import "./app/styles/angular/cdk-overlay.scss";
@import "./app/styles/angular/mat-checkbox.scss";
@import "./app/styles/angular/mat-slide-toggle.scss";
@import "./app/styles/angular/mat-table.scss";
@import "./app/styles/angular/mat-form-field.scss";
@import "./app/styles/angular/mat-paginator.scss";
@import "./app/styles/angular/mat-badge.scss";
@import "./app/styles/angular/cdk-drag-and-drop.scss";
@import "./app/styles/angular/mat-tab.scss";
@import "./app/styles/angular/mat-calendar.scss";

// CARDS
@import "./app/styles/cards.scss";

// THEMES
@import "./app/styles/themes/mat-datepicker.theme.scss";
@import "./app/styles/themes/ng-select.theme.scss";
@import "./app/styles/themes/ng-autocomplete.theme.scss";
@import "./app/styles/themes/ngx-material-timepicker.scss";
@import "./app/styles/themes/ngx-popper.scss";
@import "./app/styles/themes/ngx-skeleton-loader.scss";
@import 'tippy.js/dist/tippy.css';

* {
  font-family: RedHatText, NotoSans;
}
