.readonly {
  ng-select,
  .manager-field-text {
    pointer-events: none !important;
  }

  .manager-field-text,
  .ng-select {
    border-color: transparent !important;
  }

  .new-code-button,
  .ng-clear-wrapper,
  .ng-arrow-wrapper {
    display: none !important;
  }

  .mat-checkbox-frame {
    border-color: transparent;
  }

  [input],
  [toggle],
  language-text,
  .language-suffix,
  [contenteditable],
  input,
  textarea,
  .focus-input,
  .mat-form-field {
    border-color: transparent !important;
    cursor: default !important;
    resize: none !important;
  }

  [contenteditable],
  input,
  textarea,
  .mat-form-field {
    pointer-events: none;
  }

  .date-period-button,
  .mat-datepicker-toggle {
    display: none;
  }

  .ng-select .ng-value {
    /*margin-right: 0 !important;*/
    margin-right: 4px;
    padding: 0 !important;
  }

  textarea {
    overflow: hidden;
  }

  .language-text-wrapper {
    padding: 0 8px;
  }
}