// https://github.com/MrFrankel/ngx-popper/issues/180
popper-content {
  .ngxp__container {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &[aria-hidden="true"] {
      display: none !important;
      visibility: hidden;
    }
  }
}

//

.ngxp__container {
  border-radius: 3px !important;
  box-shadow: 0px 2px 8px 0px rgba(94, 83, 87, 0.20) !important;
  background: var(--white) !important;
  padding: 5px;
  // border-color: #80808094 !important;
  z-index: 10000005;
  cursor: default;
  border: 1px solid transparent !important;

  font-size: 15px;
  color: var(--dirty-purple);
  max-width: 700px;

  &.stripped {
    padding: 0 !important;
    border: 1px solid transparent !important;
  }

  .ngxp__inner {
    max-width: 60vw;
    height: 100%;
  }

  > .ngxp__arrow {
    border-color: var(--white) !important;
    background: none;
    border: none;
    position: relative;
    // display: none;
  }

  &[x-placement^="top"] > .ngxp__arrow {
    border: 0 !important;
    bottom: 0 !important;
    &:after,
    &:before {
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-top-color: #ffffff;
      border-width: 5px;
      margin-left: -5px;
    }
    &:before {
      border-top-color: var(--dirty-purple-8);
      border-width: 6px;
      margin-left: -6px;
    }
  }

  &[x-placement^="bottom"] > .ngxp__arrow {
    border: 0 !important;
    top: 0 !important;
    &:after,
    &:before {
      bottom: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-bottom-color: #ffffff;
      border-width: 5px;
      margin-left: -5px;
    }
    &:before {
      border-bottom-color: var(--dirty-purple-8);
      border-width: 6px;
      margin-left: -6px;
    }
  }

  &[x-placement^="left"] > .ngxp__arrow {
    border: 0 !important;
    right: 0 !important;
    &:after,
    &:before {
      left: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-left-color: #ffffff;
      border-width: 5px;
      margin-top: -5px;
    }
    &:before {
      border-left-color: var(--dirty-purple-8);
      border-width: 6px;
      margin-top: -6px;
    }
  }

  &[x-placement^="right"] > .ngxp__arrow {
    border: 0 !important;
    left: 0 !important;
    &:after,
    &:before {
      right: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-right-color: #ffffff;
      border-width: 5px;
      margin-top: -5px;
    }
    &:before {
      border-right-color: var(--dirty-purple-8);
      border-width: 6px;
      margin-top: -6px;
    }
  }
}
