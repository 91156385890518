/*.dialog-header, .dialog-footer {
  padding: 0 10px;
  width: 100%;
}*/

.dialog-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
  overflow: auto;
}

.dialog-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: 0;
  padding: 20px;
  padding-left: 25px;
  // margin-bottom: 10px;
  // padding-bottom: 10px;
  padding-bottom: 0;
  // border-bottom: 1px solid var(--dirty-purple-8);
}

.dialog-title {
  font-family: RedHatDisplay;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--dirty-purple);
  word-break: break-word;
  // margin-bottom: 15px;
  // margin-top: 20px;

  * {
    font-family: RedHatDisplay;
  }

  small {
    padding-left: 20px;
    font-size: 16px;
    font-weight: normal;
  }
}

.dialog-content {
  // display: flex;
  flex: 1 1 auto;
  padding: 10px 25px 20px 25px;
  // padding: 0 25px 20px 25px;
  // margin-bottom: 20px;
  // margin-top: 10px;
  // padding-bottom: 15px;
  overflow-y: auto;
  overflow-x: hidden;

  .dialog-title {

    &:not(.mb0) {
      margin-bottom: 10px;
    }
  }

  &.no-margin {
    margin: 0;
  }

  [body] {
    color: var(--dirty-purple);
  }

  > div {
    display: flex;
    flex-flow: column nowrap;
  }

  .field-wrapper {
    padding: 6px 0;
  }

  .field-content {
    padding: 0;
  }
}

.dialog-footer {
  width: 100%;
  // min-height: 100px;
  flex: 0;
  padding: 20px 30px;
  border-top: solid 1px var(--dirty-purple-15);
  // margin-top: 20px;
  flex-wrap: wrap;
  gap: 15px;

  >* {
    gap: 15px;
    flex-wrap: wrap;
  }

}

.dialog-content .field-label {
  width: 140px;
  min-width: 140px;
  /*padding: 8px 5px;*/
}

.dialog-footer > [footer] {
  display: flex;
  flex-direction: row;
}

.dialog-footer button {
  font-family: RedHatText;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.dialog-footer {
  button,
  lxm-button {
    min-width: 110px !important;
    height: 47px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.dialog {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.dialog-footer button:not(:first-of-type) {
  margin-left: 15px;
}

.dialog-field-text > input {
  width: 100%;
  border: none;
  outline: none;
}

.dialog-input-fixed-width-1 {
  display: inline-block;
  flex: 0 !important;
  width: 145px;
  min-width: 145px;
}

.dialog-input-fixed-width-2 {
  display: inline-block;
  flex: 0 !important;
  width: 194px;
  min-width: 194px;
}

.dialog-input-fixed-width-3 {
  display: inline-block;
  flex: 0 !important;
  width: 349px;
  min-width: 349px;
}

.modal-image {
  display: flex;
  width: 0;
  height: 215px;
  // margin-top: -30px;
  margin-top: -20px;
  margin-bottom: 10px;

  &::before {
    display: flex;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
  }
}

.dialog-section-sticky {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--dirty-purple-8);
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 101;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.dialog-column-wrapper {
  display: flex;
  position: relative;
  flex: 1 1 100%;
  flex-flow: row wrap !important;
  // height: 100%;
  min-height: 100%;
  height: 100%;
  word-break: break-word;

  .dialog-column-title {
    font-family: "RedHatDisplay";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.3px;
    color: var(--dirty-purple);
  }

  form-field {
    margin-right: 0;

    .field-wrapper {
      .field-label {
        width: 150px;
        min-width: 150px;
      }
    }
  }

  .dialog-column-sticky {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 101;
    // height: 100%;
    min-height: 100%;
  }

  .dialog-column-left {
    display: flex;
    flex-direction: column;
    padding: 5px 15px 20px 0;
    border-right: solid 1px var(--light-pink);
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }

  .dialog-column-center {
    display: flex;
    flex-direction: column;
    padding: 5px 15px 20px 15px;
    border-right: solid 1px var(--light-pink);
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }

  .dialog-column-right {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 20px 15px;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.dialog-display-product-sticky {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--dirty-purple-8);
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 101;
  // height: 75px;
  margin-top: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.warning-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/warning.png");
  }
}

.confirm-offer-supplier-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/confirm-offer-supplier.png");
  }
}

.confirm-offer-retailer-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/confirm-offer-retailer.png");
  }
}

.ok-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/ok.jpg");
  }
}

.sync-products-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/sync-products.png");
  }
}

.decision-pending-products-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/decision-pending-products-modal-image.png");
  }
}

.decision-ok-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/decision-ok-modal-image.png");
  }
}

.send-proposals-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/send-proposals.png");
  }
}

.export-excel-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/export-excel.jpg");
  }
}

.delete-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/delete.jpg");
  }
}

.reject-offer-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/reject-offer.png");
  }
}

.request-offer-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/request-offer.png");
  }
}

.calendar-publish-modal-image {
  @extend .modal-image;
  &::before {
    background-image: url("/assets/img/modal/calendar-publish.png");
  }
}
