/*.cdk-overlay-connected-position-bounding-box {
  position: fixed;
}

.navigator-overlay {
  position: absolute;
}*/

.navigator-menu {
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(94, 83, 87, 0.2);
  background-color: var(--white);
}

.navigator-user-menu {
  left: 74px;
  bottom: 47px;
  width: 207px;
}

.navigator-info-menu {
  left: 74px;
  bottom: 86px;
  width: 207px;
}

.navigator-menu .mat-menu-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 6px 0 !important;
  z-index: 1001;
}


.navigator-menu .mat-menu-content .navigator-menu-button {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  font-family: RedHatText, NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
}

.navigator-menu .mat-menu-content .navigator-menu-button:hover {
  cursor: pointer;
  background-color: var(--dirty-purple-8);
}

.navigator-menu .mat-menu-content .navigator-menu-button.active {
  background-color: var(--dirty-purple-8);
}

.navigator-menu .mat-menu-content .navigator-menu-select-button {
  padding: 0;
}

/*.navigator-menu .mat-menu-content .navigator-menu-select .mat-select-trigger {
  padding: 0 6px;
}*/

.navigator-menu-select-option, .navigator-menu-header, .navigator-menu .mat-menu-content .navigator-menu-button {
  padding: 8px 12px;
}

.navigator-menu-select-option {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  font-family: RedHatText, NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  background: white;
}

.navigator-menu-select-option:hover {
  cursor: pointer;
  background-color: var(--dirty-purple-8);
}

.navigator-menu-select-backdrop {
  box-shadow: 0 2px 8px 0 rgba(94,83,87,.2);
  background-color: var(--white);
}

.navigator-menu-header {
  display: flex;
  flex-flow: row nowrap;
  font-family: RedHatDisplay, NotoSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  text-align: left;
  padding-top: 12px;
}

.navigator-menu-header:empty {
  display: none;
}

.mat-menu-content {
  z-index: 2000;
}

.navigator-menu-tenants:not(:empty) {
  border-bottom: solid 1px var(--dirty-purple-15);
  padding-bottom: 6px;
}

.navigator-menu-tenants {
  .navigator-menu-button {
    font-family: RedHatDisplay, NotoSans !important;

    &.current {
      /*color: var(--watermelon) !important;*/
      color: var(--tangerine-2) !important;
      font-weight: 500 !important;
      pointer-events: none;
    }
  }
}
