@use "src/app/styles/breakpoints";

body {
  position: relative;
}

.cdk-overlay-container {
  position: absolute;
  z-index: 1005;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1005;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1005;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  height: 100%;
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1005;
  display: flex;
  flex-direction: column;
}

.cdk-global-overlay-wrapper {
  z-index: 1005;
}

@media screen and (max-width: breakpoints.$breakpoint-lg) {
  .cdk-overlay-container {
    .cdk-overlay-pane {
      min-width: 95vw !important;
      width: 95vw !important;
    }
  }

  .dialog-footer {
    lxm-button {
      flex: 1;
    }
  }
}

