@use "breakpoints";

:root {
  --app-background: rgba(94, 83, 87, 0.1);
  // CARD
  --card-padding: 15px 10px;
  --card-header-padding-left: 20px;
  --card-header-padding-right: 10px;
  --card-content-margin: 0 10px 0 20px;
  --card-content-aligned-margin: 10px 10px 0 10px;
  --card-content-margin-top: 15px;
  --card-header-padding: 0 10px;
  --card-background: var(--white);
  --card-box-shadow: 0 5px 10px 0 rgba(94, 83, 87, 0.1);
  --cards-gap: 12px;
  // LAYOUT
  --content-padding: 12px 30px;
  --content-buffer-bottom: 300px;
  // NAVIGATOR
  --navigator-width: 300px;
  --navigator-link-width: 205px;
  --navigator-collapsed-width: 55px;

  // Z-INDEXES
  --locker-active-z-index: 207;
  --locker-active-card-z-index: 208;

  // FORM
  --form-data-padding-left: 1em;
  --form-data-padding-right: 1.5em;
  --form-data-min-width: 200px;
  --input-min-height: 34px;
  --input-height: 34px;
  --input-border: 1px solid rgba(94, 83, 87, 0.25);
  --input-readonly-border: 1px solid transparent;
  --input-error-border: 1px solid #f2736d;
  --input-focus-border: 1px solid var(--tangerine);
  --input-border-radius: 3px;
  --input-border-transition: border 0.2s;
  --input-padding: 6px 10px;
  --input-color: var(--dirty-purple);
  --button-height: 34px;
  --button-big-height: 47px;
  --button-padding: 8px 12px;
  --form-required-color: var(--watermelon);
  --form-recommended-color: var(--azure);
  // LOADERS
  --view-spinner-size: 60px;
  --view-spinner-background: var(--white);
  --view-spinner-color: var(--tangerine);
  --view-spinner-path-color: #f3f3f3;
  --dialog-spinner-size: 50px;
  --dialog-spinner-background: var(--white);
  --dialog-spinner-color: var(--tangerine);
  // SCROLLBAR
  --scrollbar-width: 10px;
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: rgba(94, 83, 87, 0.28);
  --scrollbar-thumb-hover-color: #c5c5c5;
  // CALENDAR
  --calendar-border: solid 1px var(--dirty-purple-15);
  --calendar-row-height: 58px;
  --calendar-entry-height: 50px;
  // TABLE
  --table-cell-padding: 4px 12px 4px 12px;
  --table-header-background: var(--dirty-purple-8);
  --table-row-min-height: 58px;
  --table-row-current-background: #f9f9f9c7;
  --row-background-hover: linear-gradient(
    121deg,
    rgba(254, 239, 222, 0.6) 37%,
    rgba(254, 239, 222, 0.6) 37%
  );
  --row-background-current: linear-gradient(
    96deg,
    rgba(24, 163, 73, 0.15) 12%,
    rgba(154, 206, 129, 0.15) 87%
  );
  --row-background-upcoming: rgba(94, 83, 87, 0.04);
  --row-background-invalid: #fcf2f1;
  --row-background-rejected: #f9f9f9;
  --row-background-confirmed: #f4fbf1;
  // MISC
  --link-hover-color: var(--tangerine);
  --placeholder-color: #999;

  // SIZING
  --big: 2.875rem;
  --medium: 2.5rem;
  --small: 2.125rem;

  --1x: 0.125rem;
  --2x: 0.25rem;
  --2-5x: 0.3125rem;
  --3x: 0.375rem;
  --4x: 0.5rem;
  --5x: 0.625rem;
  --6x: 0.75rem;
  --7x: 0.875rem;
  --8x: 1rem;
  --9x: 1.125rem;
  --10x: 1.25rem;
  --11x: 1.375rem;
  --12x: 1.5rem;
  --13x: 1.625rem;
  --14x: 1.75rem;
  --15x: 1.875rem;
  --16x: 2rem;
  --17x: 2.125rem;
  --18x: 2.25rem;
  --19x: 2.375rem;
  --20x: 2.5rem;
  --21x: 2.625rem;
  --22x: 2.75rem;
  --23x: 2.875rem;
  --24x: 3rem;
  --25x: 3.125rem;
  --26x: 3.25rem;
  --27x: 3.375rem;
  --28x: 3.5rem;
  --29x: 3.625rem;
  --30x: 3.75rem;
  --31x: 3.875rem;
  --32x: 4rem;
  --33x: 4.125rem;
  --34x: 4.25rem;
  --35x: 4.375rem;
  --36x: 4.5rem;
  --37x: 4.625rem;
  --38x: 4.75rem;
  --39x: 4.875rem;
  --40x: 5rem;
  --41x: 5.125rem;
  --42x: 5.25rem;
  --43x: 5.375rem;
  --44x: 5.5rem;
  --45x: 5.625rem;
  --46x: 5.75rem;
  --47x: 5.875rem;
  --48x: 6rem;
  --49x: 6.125rem;
  --50x: 6.25rem;
  --51x: 6.375rem;
  --52x: 6.5rem;
  --53x: 6.625rem;
  --54x: 6.75rem;
  --55x: 6.875rem;
  --56x: 7rem;
  --57x: 7.125rem;
  --58x: 7.25rem;
  --59x: 7.375rem;
  --60x: 7.5rem;
}

.cards-gap {
  gap: 12px;
}

@media screen and (max-width: breakpoints.$breakpoint-xl) {
  :root {
    --content-padding: 12px 20px;
  }
}

@media screen and (max-width: breakpoints.$breakpoint-lg) {
  :root {
    --content-padding: 10px 15px;
  }
}

@media screen and (max-width: breakpoints.$breakpoint-md) {
  :root {
    --content-padding: 10px;
  }
}