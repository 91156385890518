.color-tag {
  padding-right: 5px;
  border-radius: 3px;
  border: 1px solid var(--dirty-purple-15);   

  &.color-2,
  &.color-3,
  &.color-4,
  &.color-5,
  &.color-6,
  &.color-7,
  &.color-8,
  &.color-9 {
    color: var(--white);
  }

  &.color-1 {
    background-color: var(--color-1);
  }

  &.color-2 {
    background-color: var(--color-2);
  }

  &.color-3 {
    background-color: var(--color-3);
  }

  &.color-4 {
    background-color: var(--color-4);
  }

  &.color-5 {
    background-color: var(--color-5);
  }

  &.color-6 {
    background-color: var(--color-6);
  }

  &.color-7 {
    background-color: var(--color-7);
  }

  &.color-8 {
    background-color: var(--color-8);
  }

  &.color-9 {
    background-color: var(--color-9);
  }

  &.color-10 {
    background-color: var(--color-10);
  }
}

lxm-select {

    &:not(.readonly) {
        .ng-select-multiple {
            .color-tag {
                padding-right: 0;
                margin: 0 -0.25em;
              }
        }
    }


}
