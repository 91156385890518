.search-input {
  display: flex;
  flex: 1;
  min-height: 100%;
  max-height: 100%;
  border-radius: 3px;
  outline: none;
  border: none;
  margin: 0;
  width: 100%;
}

.search-input .autocomplete-container {
  box-shadow: none;
  width: 100%;
}
