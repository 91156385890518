@import "src/app/styles/breakpoints";

.content {
  position: relative;
  display: flex;
  flex: 1 0 0;
  flex-flow: column nowrap;
  overflow: auto;
  max-height: 100dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: var(--content-padding);
  gap: var(--cards-gap);

  &:not(.content-responsive) {
    min-width: 1000px;
  }
}

router-outlet {
  display: none;
}

.data-table-card {
  display: flex;
  flex: 1;
  min-height: 80dvh;
}

.layout-wrapper {
  display: flex;
  max-width: 100%;
  flex: 1;
  gap: inherit;
}

.cards-container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  max-width: 100%;
  gap: inherit;
}

.tab-content {
  display: block;
  margin-top: 20px;
  padding: 0 16px;
}

.relative {
  position: relative;
}

.inline {
  display: inline;
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 100%;

  &.nowrap {
    flex-flow: row nowrap;
  }
}

@for $i from 1 through 12 {
  // .col-#{$i} {
    // display: flex;
    // flex-direction: column;
    // -ms-flex: 1 1 calc($i / 12 * 100%);
    // flex: 1 1 calc($i / 12 * 100%);
    // padding-right: 12px;
    // padding-left: 12px;
    
  // }

  .col-#{$i} {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    // -ms-flex: 1 1 calc($i / 12) * 100%;
    // flex: 1 1 calc($i / 12) * 100%;
    width: calc($i / 12) * 100%;

    
    @if $i != 12 {
      &:not(:last-of-type) {
        padding-right: 24px;
      }
    }
  }
}

// // DESKTOP
// @media screen and (min-width: $breakpoint-lg) { 
//   @for $i from 1 through 12 {
//     .col-#{$i} {
//       display: flex;
//       flex-direction: column;
//       flex: 0 0 auto;
//       // -ms-flex: 1 1 calc($i / 12) * 100%;
//       // flex: 1 1 calc($i / 12) * 100%;
//       width: calc($i / 12) * 100%;

//       &:not(:last-of-type) {
//         padding-right: 24px;
//       }

//       // &:not(:first-of-type) {
//       //   padding-left: 12px;
//       // }
//     }
//   }

// }

@media screen and (max-height: $breakpoint-md) { 
  .data-table-card {
    min-height: 600px;
  }
}

// TABLET
@media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) { 
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      // -ms-flex: 1 1 calc($i / 12) * 100%;
      // flex: 1 1 calc($i / 12) * 100%;
      width: calc($i / 12) * 100%;

      @if $i != 12 {
        &:not(:last-of-type) {
          padding-right: 18px;
        }
      }

      // &:not(:first-of-type) {
      //   padding-left: 12px;
      // }
    }
  }
}


// MOBILE
@media screen and (max-width: $breakpoint-sm) { 
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      // -ms-flex: 1 1 calc($i / 12) * 100%;
      // flex: 1 1 calc($i / 12) * 100%;
      width: calc($i / 12) * 100%;

      @if $i != 12 {
        &:not(:last-of-type) {
          padding-right: 12px;
        }
      }
    }
  }
}

.button-height {
  height: var(--button-height);
}

@for $i from 0 through 100 {
  .ml#{$i} {
    margin-left: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mr#{$i} {
    margin-right: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mt#{$i} {
    margin-top: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mb#{$i} {
    margin-bottom: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .p#{$i} {
    padding: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pl#{$i} {
    padding-left: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pr#{$i} {
    padding-right: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pt#{$i} {
    padding-top: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .w#{$i} {
    width: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .w#{$i}p {
    width: $i * 1%;
  }
}

@for $i from 0 through 100 {
  .h#{$i}p {
    height: $i * 1%;
  }
}

.w96 {
  width: 96px;
}

.mw96 {
  min-width: 96px;
}

.wmc {
  width: max-content;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.h90p {
  height: 90%;
}

.no-padding {
  padding: 0 !important;
}

