// svg-icon {
//   font-size: inherit !important;
// }

svg-icon.svg-icon-accept {
  &--accepted {
    color: var(--dark-sea-green);
    stroke-width: 2px;
    stroke: var(--dark-sea-green);
  }
}

svg-icon.flag-icon {
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid var(--dirty-purple-15);
}

.icon {
  display: flex;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;
}

.minus-small-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/minus-small.svg");
}

.plus-small-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/plus-small.svg");
}

.plus-white-icon {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-image: url("/assets/img/icons/plus.svg");
}

.plus-dark-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/plus-dark.svg");
}

.categories-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/categories.svg");
}

.sections-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/sections.svg");
}

.breadcrumb-arrow-icon {
  @extend .icon;
  width: 9px;
  height: 9px;
  background-image: url("/assets/img/icons/breadcrumb-arrow.svg");
}


.reply-icon {
  @extend .icon;
  width: 10px;
  height: 12px;
  background-image: url("/assets/img/icons/reply.svg");
}

.dot-menu-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/dot-menu.svg");
}

.close-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/close.svg");
}

.copy-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/copy.svg");
}

.edit-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/edit.svg");
}

.edit-white-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/edit-white.svg");
}

.back-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/back-dark.svg");
}

.save-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/save.svg");
}

.note-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/note.svg");
}

.notes-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/notes.svg");
}

@for $i from 1 through 10 {
  .notes-count-#{$i}-icon {
    @extend .notes-icon;
    align-items: center;
    &::before {
      width: 100%;
      padding-top: 2px;
      color: var(--white);
      font-size: 11px;
      line-height: 1.15;
      text-decoration: none !important;
      @if $i < 10 {
        content: "#{$i}";
      } @else {
        content: "9+";
      }
    }
  }
}

.download-icon {
  @extend .icon;
  width: 18px;
  height: 18px;
  background-image: url("/assets/img/icons/download.svg");
}

.download-images-icon {
  @extend .icon;
  width: 18px;
  height: 18px;
  background-image: url("/assets/img/icons/download-images.svg");
}

.drag-icon {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/drag.svg");
}

.accept-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/accept.svg");
}

.accept-green-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/accept-green.svg");
}

.accept-white-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/accept-white.svg");
}

.generate-icon {
  @extend .icon;
  width: 18px;
  height: 19px;
  background-image: url("/assets/img/icons/generate-small.svg");
}

.generate-small-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/generate-small.svg");
}

.cogs-icon {
  @extend .icon;
  width: 21px;
  height: 17px;
  background-image: url("/assets/img/icons/fa-cogs-regular.svg");
  filter: invert(34%) sepia(8%) saturate(478%) hue-rotate(287deg) brightness(93%) contrast(92%);
  &.disabled {
    background-image: url("/assets/img/icons/fa-cogs-regular-disabled.svg");
  }
}

.minus-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/fa-minus.svg"), url("/assets/img/icons/fa-minus.svg");
}

.address-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/icon-address.svg");
}

.crossmark-sm-icon {
  @extend .icon;
  width: 11px;
  height: 11px;
  background-image: url("/assets/img/icons/crossmark-sm.svg");
}

.crossmark-lg-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/crossmark-lg.svg");
}

.product-info-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/product-info.svg");
}

.product-info-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/product-info.svg");
}

.product-info-partly-filled-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/product-info-partly-filled.svg");
}

.product-info-filled-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/product-info-filled.svg");
}

.assortment-scheme-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/assortment-scheme.svg");
}

.assortment-scheme-partly-filled-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/assortment-scheme-partly-filled.svg");
}

.assortment-scheme-filled-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/assortment-scheme-filled.svg");
}

.assortment-scheme-filled-without-decision-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/assortment-scheme-filled-without-decision.svg");
}

.price-change-empty-icon {
  @extend .icon;
  width: 18px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/price-change-empty.svg");
}

.price-change-filled-icon {
  @extend .icon;
  width: 18px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/price-change-filled.svg");
}

.retailer-supplier-prices-info-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info.svg");
}

.retailer-supplier-prices-info-empty-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-empty.svg");
}

.retailer-supplier-prices-info-empty-with-price-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-empty-with-price.svg");
}

.retailer-supplier-prices-info-empty-with-logistics-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-empty-with-logistics.svg");
}

.retailer-supplier-prices-info-empty-with-price-with-logistics-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-empty-with-price-with-logistics.svg");
}

.retailer-supplier-prices-info-filled-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-filled.svg");
}

.retailer-supplier-prices-info-filled-without-price-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-filled-without-price.svg");
}

.retailer-supplier-prices-info-filled-without-logistics-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-filled-without-logistics.svg");
}

.retailer-supplier-prices-info-filled-without-price-without-logistics-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-filled-without-price-without-logistics.svg");
}

.retailer-supplier-prices-info-empty-without-procurement-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-empty-without-procurement.svg");
}

.retailer-supplier-prices-info-filled-with-procurement-icon {
  @extend .icon;
  width: 25px;
  height: 17px;
  background-image: url("/assets/img/icons/retailer-supplier-prices-info/retailer-supplier-prices-info-filled-with-procurement.svg");
}

.campaign-info-empty-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/campaign-info-empty.svg");
}

.campaign-info-filled-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/campaign-info-filled.svg");
}

.x-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/x.svg");
}

.x-red-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/x-red.svg");
}

.x-white-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/x-white.svg");
}

.undelete-icon {
  @extend .icon;
  width: 18px;
  height: 19px;
  background-image: url("/assets/img/icons/undelete.svg");
}

.trash-icon {
  @extend .icon;
  width: 18px;
  height: 19px;
  background-image: url("/assets/img/icons/trash.svg");
}

.date-small-icon {
  @extend .icon;
  width: 15px;
  height: 16px;
  background-image: url("/assets/img/icons/date-small.svg");
}

.info-icon {
  @extend .icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/img/icons/info.svg");
}

.export-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/export.svg");
}

.required-icon {
  @extend .icon;
  width: 7px;
  height: 7px;
  background-image: url("/assets/img/icons/required.svg");
}

.excel-icon {
  @extend .icon;
  width: 19px;
  height: 19px;
  background-image: url("/assets/img/icons/excel.svg");
}

.ecomm-icon {
  @extend .icon;
  width: 36px;
  height: 17px;
  background-image: url("/assets/img/icons/ecomm.svg");
}

.xml-icon {
  @extend .icon;
  width: 19px;
  height: 19px;
  background-image: url("/assets/img/icons/xml.svg");
}

.pdf-icon {
  @extend .icon;
  width: 19px;
  height: 19px;
  background-image: url("/assets/img/icons/pdf.svg");
}

.send-icon {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-image: url("/assets/img/icons/send.svg");
}

.check-icon {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-image: url("/assets/img/icons/fa-check.svg");
}

.check-small-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/check-small.svg");
}

.checkmark-icon {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/accept.svg");
}

.checkmark-white-icon {
  @extend .icon;
  width: 18px;
  height: 16px;
  background-image: url("/assets/img/icons/accept-white.svg");
}

.check-double-icon {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-image: url("/assets/img/icons/check-double.svg");
}

.check-green-small-icon {
  @extend .icon;
  display: block;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/check-green-small.svg");
}

.undo-icon {
  @extend .icon;
  width: 17px;
  height: 16px;
  background-image: url("/assets/img/icons/undo.svg");
}

.profile-dark-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: var(--very-light-pink);
  background-size: 10px;
  background-position: center;
  background-image: url("/assets/img/icons/profile-dark.svg");
}

.dot-active-icon {
  @extend .icon;
  width: 12px;
  height: 12px;
  background-image: url("/assets/img/icons/dot-active.svg");
}

.tag-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/tag.svg");
}

.profile-status-icon {
  @extend .icon;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-position: center;
  background-image: url("/assets/img/icons/profile-dark-cut.svg");

  &.status-active {
    &::after {
      position: absolute;
      top: -3px;
      right: -6px;
      content: "";
      width: 12px;
      height: 12px;
      background-image: url("/assets/img/icons/dot-active.svg");
    }
  }

  &.status-disabled {
    &::after {
      position: absolute;
      top: -3px;
      right: -6px;
      content: "";
      width: 12px;
      height: 12px;
      background-image: url("/assets/img/icons/dot-disabled.svg");
    }
  }

  &.status-linked {
    &::after {
      position: absolute;
      top: -3px;
      right: -5px;
      content: "";
      width: 12px;
      height: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("/assets/img/icons/link.svg");
    }
  }

}

.profile-grey-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/profile-grey.svg");
}

.user-small-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/user.svg");
}

.campaign-icon-sm {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/campaign.svg");
}

.campaign-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/campaign.svg");
}

.error-icon {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/error.svg");
}

.maximize-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/maximize.svg");
}

.minimize-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/minimize.svg");
}

.expanded-row-icon {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/table-row-plus.svg");
  cursor: pointer;
  &.active {
    background-image: url("/assets/img/icons/table-row-minus.svg");
  }
}

.arrow-left-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/arrow-left.svg");
}

.arrow-right-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/arrow-right.svg");
}

.arrow-right-long-icon {
  @extend .icon;
  display: inline-flex;
  width: 14px;
  height: 12px;
  background-image: url("/assets/img/icons/arrow-right-long.svg");
}

.arrow-menu-collapse-icon {
  @extend .icon;
  width: 11px;
  height: 11px;
  background-image: url("/assets/img/icons/arrow-menu-collapse.svg");
}


// PRODUCT UNIT/PACKAGING ICONS
.deposit-icon {
  @extend .icon;
  position: relative;
  width: 18px;
  height: 18px;
  background-image: url("/assets/img/icons/recycle.svg");
  cursor: pointer;

  &.disabled {
    background-image: url("/assets/img/icons/recycle-disabled.svg");
  }
}

.material-icon {
  @extend .icon;
  position: relative;
  width: 18px;
  height: 18px;
  background-image: url("/assets/img/icons/package-material.svg");
  cursor: pointer;
  &.disabled {
    background-image: url("/assets/img/icons/package-material-disabled.svg");
  }
}

.product-unit-trash-icon {
  @extend .icon;
  position: relative;
  width: 18px;
  height: 19px;
  background-image: url("/assets/img/icons/trash.svg");
  margin-top: -1px;
  cursor: pointer;
  // &.disabled {
  //   background-image: url("/assets/img/icons/trash-disabled.svg");
  // }
}

.view-white-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/view-white.svg");
}

.view-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/view.svg");
}

.search-button-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/search.svg");
}

.search-small-icon {
  @extend .icon;
  width: 14px;
  height: 14px;
  background-image: url("/assets/img/icons/search-small.svg");
}

.filter-button-active-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/filter-active.svg");
  position: relative;
}

.filter-button-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/filter.svg");
  position: relative;
}

.timeline-empty-icon {
  @extend .icon;
  width: 150px;
  height: 150px;
  background-image: url("/assets/img/icons/timeline-empty.svg");
}

.saved-button-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/saved.svg");
}

.bookmark-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/bookmark.svg");
}

.cancel-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/trash.svg");
}

.close-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/close.svg");
}

.imports-icon {
  @extend .icon;
  width: 17px;
  height: 18px;
  background-image: url("/assets/img/icons/imports.svg");
}

.direction-left-icon {
  @extend .icon;
  width: 20px;
  height: 15px;
  background-image: url("/assets/img/icons/icon-direction-left.svg");
}

.direction-right-icon {
  @extend .icon;
  width: 20px;
  height: 15px;
  background-image: url("/assets/img/icons/icon-direction-right.svg");
}

.additional-menu-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/additional-menu.svg");
}

.pro-icon {
  @extend .icon;
  width: 33px;
  height: 14px;
  background-image: url("/assets/img/icons/pro.svg");
}

.warning-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/warning.svg");
}

.product-procurement-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-procurement.svg");
}

.product-procurement-sm-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-procurement-sm.svg");
}

.product-invalid-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-invalid.svg");
}

.product-invalid-sm-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-invalid-sm.svg");
}

.product-campaign-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-campaign.svg");
}

.product-campaign-sm-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-campaign-sm.svg");
}

.product-main-price-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-main-price.svg");
}

.product-price-group-sm-icon {
  @extend .icon;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/icons/product-price-group-sm.svg");
}

.product-order-details-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/product-order-details.svg");
}

.link-arrow-icon {
  @extend .icon;
  width: 10px;
  height: 10px;
  background-image: url("/assets/img/icons/link-arrow.svg");
}

.replace-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/replace.svg");
}

.product-replacement-in-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/product-replacement-in.svg");
}

.product-replacement-out-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/product-replacement-out.svg");
}

.edi-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/edi.svg");
}

.mark-as-expired-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/mark-as-expired.svg");
}

.suspend-product-icon {
  @extend .icon;
  width: 17px;
  height: 17px;
  background-image: url("/assets/img/icons/suspend-product.svg");
}
