.ng-autocomplete .autocomplete-container {
  box-shadow: none;
}

.ng-autocomplete .autocomplete-container .input-container {
  /*display: flex;
  flex: 1;*/
}

.ng-autocomplete .autocomplete-container .input-container input:disabled {
    background-color: inherit;
    color: var(--dirty-purple);
}

.ng-autocomplete .autocomplete-container .input-container input:focus {
    border-color: var(--tangerine);
}

.autocomplete-container .suggestions-container {
  margin-top: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(94, 83, 87, 0.2);
  border: none;
}

.ng-autocomplete .autocomplete-container .suggestions-container ul li a {
  background-color: var(--white);
  /* color: rgba(0,0,0,0.87); */
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  padding: 8px 10px;
  height: 40px;
  display: flex;
  align-items: center
}

.ng-autocomplete .autocomplete-container .suggestions-container ul:hover li:hover a:hover {
  background-color: var(--very-light-pink);
  color: #333;
}

.ng-autocomplete .autocomplete-container .input-container input {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  display: flex;
  border: var(--input-border);
  border-radius: 3px;
  background-color: var(--white);
  outline: none;
  padding: 8px 10px;
  height: var(--input-height);
  min-height: var(--input-min-height);
  overflow: hidden;
  white-space: pre-line;
}












/* SEARCH PANEL INPUT */

.search-input-container {
    height: 47px;
}

.search-input.ng-autocomplete,
.search-input .autocomplete-container .input-container input {
  height: 21px;
}

.search-input .autocomplete-container {
  min-height: 100%;
  max-height: 100%;
}
