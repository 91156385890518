* {
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
}

body {
  display: flex;
  flex: 1;
  margin: 0;
  background-color: var(--app-background);
  font-family: RedHatText, NotoSans;
  overflow: hidden;
  min-width: 100vw;
  max-width: 100vw;
}

.app-container {
  display: flex;
  flex: 1;
  min-height: 100dvh;
  height: 100dvh;
  min-width: 100vw;
  width: 100vw;
  overflow: hidden;
}

app-home {
  display: flex;
  flex-direction: row;
}
