ngx-material-timepicker-container {
    z-index: 1001;
}

ngx-material-timepicker-face {
    .clock-face {
        height: 250px !important;
        padding: 0 20px !important;
    }
}

ngx-material-timepicker-dial-control {
    .timepicker-dial__control {
        outline: none;
    }
}

ngx-timepicker-field {
    .ngx-timepicker {
        color: var(--dirty-purple);
        // border: solid 1px rgba(94, 83, 87, 0.25);
        border-radius: 3px;
    }
}

ngx-material-timepicker-toggle {

    > button {
        > span {
            display: flex;
            svg {
                fill: var(--dirty-purple-70);
            }
        }
    }
    // svg {
    //     fill: var(--dirty-purple-70);
    // }
}
