/* noto-sans-regular - cyrillic-ext_cyrillic */
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans'), local('NotoSans'),
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-700 - cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans Bold'), local('NotoSans-Bold'),
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700.svg#NotoSans') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-italic - cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans Italic'), local('NotoSans-Italic'),
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-italic.svg#NotoSans') format('svg'); /* Legacy iOS */
  }
  /* noto-sans-700italic - cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/NotoSans/noto-sans-v9-cyrillic-ext_cyrillic-700italic.svg#NotoSans') format('svg'); /* Legacy iOS */
  }
