.replacement-indicator-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  .replacement-indicator-horizontal {
    position: absolute;
    left: 8px;
    top: 0;
    height: 10px;
    border: 1px solid var(--color-2);
    top: calc(50% - 5px);
    transform: rotate(90deg);
  }

  .replacement-indicator-vertical {
    position: absolute;
    left: 4px;
    bottom: 0;
    height: 100%;
    border: 1px solid var(--color-2);
  }

  &.replacement {
    .replacement-indicator-horizontal {
      border: 1px solid var(--color-2);
    }
    .replacement-indicator-vertical {
      border: 1px solid var(--color-2);
    }
  }

  &.replaced {
    .replacement-indicator-horizontal {
      border: 1px solid var(--salmon);
    }
    .replacement-indicator-vertical {
      height: 50%;
      border: 1px solid var(--salmon);
    }
  }
}

.mat-table {
  .mat-row {
    &.row-replacement,
    &.row-replaced {
      &:not(.row-group-end) {
        border-bottom: none;
      }
    }
  }
}

.row-group-end {
  .replacement-indicator-container {
    .replacement-indicator-vertical {
      top: 0;
      height: 50%;
    }
  }
}

.row-replaced,
.row-suspended {
  .mat-cell:not(.mat-column-actions):not(.mat-column-product_replacement):not(.mat-column-product_replacement):not(.mat-column-product_suspension):not(.mat-column-replacement_indicator)
    * {
    opacity: 0.8;
  }
}
