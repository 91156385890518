// TODO - Move remaining classes over to tailwind

.f-0 {
  flex: 0;
}

.f-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.row.flex-nowrap,
.flex-nowrap {
  flex-wrap: nowrap !important;
}

.align-center {
  align-items: center !important;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-start {
  align-self: flex-start;
}