.mat-row {
  &.current {
    background-image: var(--row-background-current);
  }

  &.upcoming {
    background: var(--row-background-upcoming);
  }

  &.expired {
    opacity: 0.55;
  }
}

.table-row-hover {
  &:hover,
  &.active {
    background: var(--row-background-hover);

    &:not(.cursor-default) {
      cursor: pointer;
    }
  }

  &:hover {
    lxm-select:not(.readonly) {
      background: var(--white);
    }
  }

  &.invalid {
    background: var(--row-background-invalid);

    &:hover {
      background: var(--row-background-hover);
    }

    lxm-select:not(.readonly) {
      background: var(--white);
    }
  }

  &.new {
    mat-cell {
      font-weight: 500;
    }
  }
}

.table-group-row {
  height: 34px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    transition: background-color 0.3s ease;
    // opacity: 0.8;
  }

  td {
    height: 34px;
    border-radius: 3px;
  }

  .table-group-label {
    display: flex;
    align-items: center;
    // padding: 0rem var(--5x, 0.625rem);
    height: 100%;
    border-radius: 3px;
    user-select: none;
  }

  &.group--isNew {
    background-color: var(--green-warm-chinise-white);
    &:hover {
      background-color: var(--green-warm-lime);
    }
  }

  &.group--isExpired {
    background-color: var(--valerie);

    &:hover {
      background-color: var(--rosey-afterglow);
    }
  }

  &.group--isChange {
    background-color: var(--grey-10);

    &:hover {
      background-color: var(--grey-20);
    }
  }
}

.mat-row.table-group-row {
  height: 34px !important;
  min-height: 34px !important;
  user-select: none;
}

.mat-cell.table-group-cell {
  min-width: 0px;
  max-width: 0px;
  overflow: visible;
  white-space: nowrap;

  &.level-2 {
    color: var(--dirty-purple-55);
    left: 12px;
  }
}

// .products-table-row.invalid {

// }

// .products-table-row.invalid:hover {
// }

.products-table-total {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  margin-top: 12px;
  margin-left: 10px;
}

.mat-cell-center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.triple-rows {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 100%;
  .cell-row {
    min-height: 18px;
    width: 100%;
  }
}

.cell-center {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
}

.cell-end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-align: end;
}

.mat-row {
  &.readonly {
    .mat-cell {
      lxm-input,
      lxm-datepicker,
      lxm-textarea {
        background: inherit;
      }

      .lxm-textarea-wrapper {
        border-color: transparent;
      }
    }
  }
}

.mat-cell {
  &.mat-table-sticky {
    z-index: 2 !important;
  }

  .readonly {
    .ng-select-container,
    .ng-value-container {
      padding: 0 !important;
    }
  }
  .input-readonly,
  .input-padding,
  .datepicker-readonly {
    padding: 0 !important;
  }

  .input-readonly {
    display: unset;
    padding: 0;
    text-align: inherit;
  }
}

td,
mat-cell {
  .lxm-input {
    width: 100%;
  }
}

.lxm-tree-header {
  height: 100%;
  align-items: center;
}

.mat-header-row .mat-header-cell:not(:first-of-type):not(:last-of-type) {
  border-radius: 0 !important;
}

.mat-header-row .mat-header-cell:last-of-type {
  border-radius: 0 3px 3px 0;
}

.mat-header-row .mat-header-cell:first-of-type {
  border-radius: 3px 0 0 3px;
}

.table-checkbox.mat-checkbox-disabled {
  display: none !important;
}

.mat-cell {
  outline: none;
}

.mat-table.no-row-separator .mat-cell,
.mat-table.no-row-separator .mat-footer-cell,
.mat-table.no-row-separator .mat-header-cell {
  border-bottom: none !important;
}

.mat-table.no-table-header .mat-header-row {
  display: none !important;
}

/* EXPANDABLE ROWS */

.element-row {
  position: relative;
}

.element-row.expanded-row {
  border-bottom-color: transparent;
}

.mat-row.detail-row {
  display: none;
  // height: 0 !important;
  // min-height: unset !important;

  &.expanded-row {
    display: flex;
    // height: unset !important;
  }
}

.element-row .mat-cell {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
  width: 100%;
}

.element-detail {
  border-radius: 3px;
  // background-color: rgba(94, 83, 87, 0.07);
  // padding: 15px 20px;
}

.element-label {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
}

.element-description {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
}

.element-row {
  position: relative;
}

.element-row.expanded-row {
  border-bottom-color: transparent;
}

/**/

.mat-cell.overflow-cell {
  overflow: visible;
}

/**/

// .loader,
// .loader:before,
// .loader:after {
//   border-radius: 50%;
// }

// .loader {
//   font-size: 10px;
//   margin: 50px auto;
//   text-indent: -9999em;
//   width: 11em;
//   height: 11em;
//   border-radius: 50%;
//   background: #000000;
//   background: -moz-linear-gradient(left, #000000 10%, rgba(0, 0, 0, 0) 42%);
//   background: -webkit-linear-gradient(left, #000000 10%, rgba(0, 0, 0, 0) 42%);
//   background: -o-linear-gradient(left, #000000 10%, rgba(0, 0, 0, 0) 42%);
//   background: -ms-linear-gradient(left, #000000 10%, rgba(0, 0, 0, 0) 42%);
//   background: linear-gradient(to right, #000000 10%, rgba(0, 0, 0, 0) 42%);
//   position: relative;
//   animation: load3 1.4s infinite linear;
//   transform: translateX(-50%) translateY(-50%) translateZ(0);
//   position: absolute;
//   left: 50%;
//   top: 50%;
// }

// .loader:before {
//   width: 50%;
//   height: 50%;
//   background: #000000;
//   border-radius: 100% 0 0 0;
//   position: absolute;
//   top: 0;
//   left: 0;
//   content: "";
// }

// .loader:after {
//   background: white;
//   width: 75%;
//   height: 75%;
//   border-radius: 50%;
//   content: "";
//   margin: auto;
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
// }

//

.role-table-head,
.user-table-head,
.clv-table-head {
  .mat-header-cell {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  text-filter {
    width: 100%;
  }
}

table {
  th {
    font-family: RedHatDisplay;
    // font-size: 14px;
    // font-weight: bold;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: normal;
    // letter-spacing: normal;
    // word-break: break-word;
    // box-sizing: border-box;
    // color: #5e5357;
    // color: var(--dirty-purple);
    // text-align: start;

    text-align: start;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dirty-purple);
    align-items: center;
    align-self: flex-start;

    padding: var(--table-cell-padding);

    // &:first-of-type {
    //   padding-left: 0;
    // }

    // &:last-of-type {
    //   padding-right: 0;
    // }
  }

  td {
    .input-readonly {
      padding-left: 0;
    }

    padding: var(--table-cell-padding);

    // &:first-of-type {
    //   padding-left: 0;
    // }

    // &:last-of-type {
    //   padding-right: 0;
    // }
  }
}

.sticky-table {
  display: flex;
  flex: 1;
  flex-direction: column;

  .sticky-table-body {
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;
    overflow-x: auto;
    will-change: transform;

    .mat-table {
      position: absolute;

      .mat-header-row {
        position: sticky;
        top: 0;
        background: white;
        opacity: 1;
        z-index: 10000000;
        .mat-header-cell {
          background: var(--dirty-purple-8);

          &.mat-table-sticky {
            background: white;

            &::before {
              content: "";
              position: absolute;
              background: var(--dirty-purple-8);
              height: 100%;
              width: 100%;
              left: 0;
              // margin-left: -12px;
            }
          }
        }
      }

      .mat-row {
        &.table-row-hover {
          &:hover {
            .mat-cell {
              &.mat-table-sticky {
                &::before {
                  background: var(--white);
                }
                &::after {
                  background: var(--row-background-hover);
                }
              }
            }
          }
        }

        &.invalid {
          .mat-cell {
            &.mat-table-sticky {
              &::before {
                background: var(--row-background-invalid);
              }
            }
          }
        }

        &:hover {
          .mat-cell {
            &.mat-table-sticky {
              &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                // margin-left: -12px;
                z-index: -1;
                background-repeat: no-repeat;
              }
            }
          }
        }

        .mat-cell {
          &.mat-table-sticky {
            &::before {
              content: "";
              position: absolute;
              background: var(--white);
              height: 100%;
              width: 100%;
              left: 0;
              // margin-left: -12px;
              z-index: -1;
              box-shadow: inherit;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .sticky-table-footer {
    display: flex;
    flex: 0;
    justify-content: flex-end;
    align-items: center;
  }
}

.clickable-cell {
  position: relative;
  cursor: pointer;

  &:hover {
    border-radius: 3px;
    box-shadow: 0 0 0 1px inset var(--tangerine);
    // opacity: 0.8;
    cursor: pointer;

    &::after {
      display: flex;
      bottom: 6px !important;
      right: 2px !important;
      left: unset !important;
      position: absolute;
      width: 17px !important;
      height: 18px !important;
      content: "";
      background-image: url("/assets/img/icons/edit.svg");
    }
  }
}
