h1 {
  font-family: RedHatDisplay;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: var(--dirty-purple);
  margin: 0;
}
h2 {
  font-family: RedHatDisplay;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--dirty-purple);
  margin: 0;
}
h3 {
  font-family: RedHatDisplay;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--dirty-purple);
  margin: 0;
}
h4 {
  font-family: RedHatDisplay;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--dirty-purple);
  margin: 0;
}

.campaign-scheme { font-weight: 500; color: var(--rusty-orange); };
.procurement-scheme { font-weight: 500; color: var(--azure); };

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
  justify-content: flex-end;
}

.link {
  color: var(--dirty-purple);
  text-decoration: none;

  &:hover {
    // color: var(--tangerine);
    cursor: pointer;
    opacity: 0.9;
    text-decoration: underline;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.arrow-link {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);

  &:hover {
    color: rgba(94, 83, 87, 0.9);
    text-decoration: underline;
  }

  &::after {
    content: '';
    display: inline-flex;
    margin-left: 4px;
    width: 10px;
    height: 10px;
    background-image: url("/assets/img/icons/link-arrow.svg");
  }
}

.multiline-text,
.pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
}

.alt-text {
  display: flex;
  align-items: center;
  font-family: RedHatText;
  font-size: 12px;
  color: var(--light-mauve);
}

b, strong {
  font-family: RedHatText;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  font-weight: 500;
}

.bold {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  font-weight: 500;
}

.greyed {
  color: var(--dirty-purple-70);
}

.dirty-purple {
  color: var(--dirty-purple);
}

.text-red {
  color: var(--red) !important;
}

.text-green {
  color: var(--dark-sea-green) !important;
}

.text-blue {
  color: var(--azure) !important;
}

.text-watermelon {
  color: var(--watermelon) !important;
}

.text-dark-peach {
  color: var(--dark-peach) !important;
}

.text-salmon {
 color: var(--salmon) !important;
}

.highlighted {
  font-weight: 500;
  color: var(--rusty-orange);
}

a {
  text-decoration: none;
  cursor: pointer;
  &.clickable,
  &[href^="mailto:"] {
    &:hover {
      opacity: 0.8;
    }
  }
  &[href],
  &[routerLink] {
    &.highlighted {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-through {
  text-decoration: line-through;
}

.title {
    font-family: RedHatDisplay;
}

.strong {
    font-weight: 500;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */

  &::placeholder {
    color: var(--placeholder-color);
  }
}

quill-editor,
quill-view,
quill-view-html {

  b, strong {
    font-weight: 500;
  }

  .ql-editor {
    padding: var(--input-padding);
  }

  .ql-container {
    font-family: RedHatText, NotoSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    background: inherit;
    color: var(--input-color);
  }
}

quill-editor {
  display: flex;
  flex-direction: column;

  .ql-container.ql-snow {
    border: var(--input-border);
    border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
  }

  .ql-toolbar.ql-snow {
    border: var(--input-border);
    border-radius: var(--input-border-radius) var(--input-border-radius) 0 0;
  }

  &:focus-within {
    .ql-container.ql-snow {
      border: var(--input-focus-border);
    }
    .ql-toolbar.ql-snow {
      border-bottom: var(--input-focus-border);
    }
  }
  
}

quill-view-html,
quill-view {
  .ql-editor {
    padding: var(--input-padding);
    padding-left: 0;
  }
}