input,
[contenteditable],
.ng-select .ng-select-container,
.lxm-input,
lxm-select .ng-select,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  font-family: RedHatText, NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  background: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}



// LXM INPUTS
.lxm-input.readonly:placeholder-shown {
  visibility: hidden;
  position: absolute;
  width: 0;
}

.lxm-input-default {
  display: none;
  padding: var(--input-padding);
}

.lxm-input.readonly:placeholder-shown + .lxm-input-default {
  display: initial;
}

.lxm-input {
  &:not(.input-readonly) {
    display: inline-flex;
  }
  width: 100%;
  padding: var(--input-padding);
  min-height: var(--input-min-height);
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  background-color: inherit;
  color: var(--input-color);
  outline: none;
}

.input-readonly {
  display: block;
  white-space: pre-line;
  word-break: break-word;
  text-overflow: ellipsis;
  border: var(--input-readonly-border) !important;
  text-align: inherit;
}

.input-padding {
  padding: var(--input-padding);
}

input.lxm-input {
  height: var(--input-height);
}


.mat-form-field,
.mat-form-field[combined],
.lxm-input,
.lxm-textarea,
.ng-select,
.combined-input {
  &.ng-invalid {
    border: var(--input-error-border);
  }
}

.lxm-input,
.lxm-textarea {
  &:disabled {
    border-color: transparent !important;
    cursor: default !important;
    resize: none !important;
  }
}

// COMBINED
.combined-field {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.combined-field > input {
  width: 80%;
  border: none;
  outline: none;
}

.combined-field > span {
  width: 20%;
  user-select: none;
  text-align: center;
}

combined-input input {
  border: none !important;
}

/* FOCUS */

.focus-input {
  display: flex;
  flex: 1;
  padding: var(--input-padding);
  min-height: var(--input-min-height);
  border-radius: var(--input-border-radius);
  border: 1px solid transparent;
  outline: none;
  resize: none;
  max-width: 100%;
  cursor: text;
}

.focus-input:focus {
  border: var(--input-focus-border);
  background-color: var(--white);
}

.input-focus-within:focus-within {
  border: solid 1px var(--tangerine) !important;
}

.input-focus:focus {
  border: solid 1px var(--tangerine) !important;
}

/*.lxm-input:focus {
  border: solid 1px var(--tangerine) !important;
}*/

.field-data textarea,
.field-data .field-text,
.lxm-input {
  &:focus {
    border: solid 1px var(--tangerine);
  }
}

.dialog-field-text:focus-within,
.lxm-textarea-wrapper:focus-within {
  border: solid 1px var(--tangerine);
}

/* DATES */
.date-range {
  display: flex;

  &--edit {
    width: auto;
  }

  &__separator {
    line-height: 32px; 
    padding: 0 5px;
  }
}

/* MATERIAL */
.mat-form-field-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

lxm-datepicker .mat-form-field input {
  display: flex;
  padding: 8px 10px;
  min-height: var(--input-min-height);
  border-radius: var(--input-border-radius);
  background-color: var(--white);
  outline: none;
  font-family: RedHatText, NotoSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
}


form-field {
  status-indicator {
    padding: var(--input-padding);
  }
}

.input-padding {
  min-height: var(--input-min-height);
  padding: var(--input-padding);
}