.ng-select {
  width: 100%;
  border-radius: 3px;
  border: solid 1px rgba(94, 83, 87, 0.25);
  min-height: var(--input-min-height);
  align-items: center;

  &.ng-select-opened {
    z-index: 101; // index 1 above lxm-overlay
  }

  &.ng-select-focused {
    border: solid 1px var(--tangerine);
  }
}

.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  /*border-bottom-right-radius: 0;
  border-bottom-left-radius: 0*/
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  /*border-top-right-radius: 0;
  border-top-left-radius: 0*/
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  /*border-color: #007eff;*/
  /*box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)*/
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: inherit;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none
}

.ng-select .ng-spinner-loader {
  border-left-color: var(--tangerine) !important;
  top: 7px;
}

.ng-select .ng-select-container {
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  min-height: calc(var(--input-min-height) - 2px);
}

.ng-select .ng-select-container:hover {
  /*box-shadow: 0 1px 0 rgba(0,0,0,0.06)*/
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding: var(--input-padding);
  padding-right: 0;
  white-space: normal !important;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--placeholder-color);
}

.ng-select.ng-select-single .ng-select-container {
  /*height: var(--input-height);*/
  /*min-height: calc(var(--input-min-height) - 2px);*/
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: unset !important;
  white-space: normal !important;
  margin-right: 2px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 44px
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 10px;
  padding-left: 44px
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  /*background-color: #f9f9f9;
  border: 1px solid #e6e6e6*/
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: var(--input-padding);
  padding-right: 0;
  padding-bottom: 0;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 8px;
  padding-left: 0;
}

.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value-wrapper {
  display: flex;
  flex-direction: row;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-family: RedHatText;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  font-size: .9em;
  background-color: var(--very-light-pink);
  border-radius: 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 .25em;
  white-space: normal !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-bottom: 3px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: var(--pale-mauve);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid var(--dirty-purple-8);
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: 5px;
  padding-left: 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0
}

/*.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}*/

.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir="rtl"] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  /*padding-right: 5px*/
  align-self: flex-start;
  padding: 8px;
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px
}

.ng-dropdown-panel {
  background-color: var(--white);
  /*border: solid 1px rgba(94, 83, 87, 0.25);*/
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(94, 83, 87, 0.2);
  left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  /*border-top-color: #e6e6e6;*/
  /*margin-top: -1px*/
  margin-top: 5px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px
}

// .ng-dropdown-panel .ng-dropdown-header {
//   border-bottom: 1px solid #ccc;
//   padding: 5px 7px
// }

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: var(--dirty-purple);
  cursor: pointer;
  font-size: 15px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: var(--very-light-pink);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  /*background-color: #fff;*/
  background-color: var(--white);
  /*color: rgba(0,0,0,0.87);*/
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  padding: 8px 10px;
  white-space: normal !important;
  word-break: break-word;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--very-light-pink);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--very-light-pink);
  color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: var(--dirty-purple-55);;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 10px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 10px;
  padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
  direction: rtl;
  text-align: right
}

  

.ng-option-disabled {
  .mat-checkbox {
    .mat-checkbox-inner-container {
      opacity: 0;
    }
    .mat-checkbox-label {
      color: inherit;
    }
  }
}


// READONLY

.readonly {
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 4px;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-right: 4px !important;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 14px;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: inherit;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: none;
  }
  .ng-select .ng-value-container .ng-value .ng-value-label {
    user-select: all !important;
  }
  .ng-select .ng-value-container .ng-value:not(:nth-last-of-type(2)) .ng-value-label {
    padding-right: 0 !important;
  }
  .ng-select.ng-select-multiple .ng-value-container .ng-value:nth-of-type(1) .ng-value-label {
    padding-left: 0 !important;
  }
}