.mat-slide-toggle-thumb {
  box-shadow: 0 1px 2px 1px rgba(94, 83, 87, 0.2);
  border: solid 1px var(--dirty-purple-15);
  background-color: var(--white);
}

.mat-slide-toggle-bar {
  border-radius: 5px;
  background-color: var(--light-pink);
}

.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--viridian);
}

.mat-checked .mat-slide-toggle-bar {
  background-color: var(--pale-olive-green);
}
