div,
span {
  &[title] {
    cursor: help;
  }

  &[mat-sort-header] {
    width: 100%;
  }
}

.tippy-arrow {
  color: var(--grey-100);
}

.tippy-box {
  color: var(--white);
  font-family: RedHatDisplay, NotoSans;
  background: var(--grey-100);
  border-radius: 3px;
  opacity: 1;
}

.no-tab-header > .mat-tab-header {
  display: none;
}

.no-tab-header .mat-tab-header {
  display: none;
}

.separator {
  height: 1px;
  width: 100%;
  border: solid 1px var(--dirty-purple-15);
  margin: 10px 0;
  opacity: 0.6;
}

.cdk-drag-preview {
  background-color: white;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.disabled--05 {
  pointer-events: none;
  opacity: 0.5;
}

.disabled--03 {
  pointer-events: none;
  opacity: 0.3;
}

.coming-soon {
  opacity: 0.6;
  pointer-events: none;
}

.hidden,
.hidden * {
  opacity: 0 !important;
  user-select: none !important;
  pointer-events: none !important;
  cursor: default !important;
}

.no-display {
  display: none !important;
}

.lxm-hover:hover {
  background-image: var(--row-background-hover);
  cursor: pointer;
}

.overflow-x {
  max-width: 100%;
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.warning-message {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  user-select: none;
}

.timeframe {
  font-size: 14px;
  color: var(--dirty-purple-70);
}

.emphasized-key {
  border-radius: 2px;
  background-color: var(--very-light-pink);
  font-weight: 500;
  padding: 2px;
  color: var(--dirty-purple);
}

// EXPANDER
expander.ng-animating {
  .mat-table {
    overflow: hidden;
  }

  .overflow-x {
    overflow: hidden;
  }
}

.procurement-header {
  * {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dirty-purple);
  }

  .procurement-header__name {
    margin-right: 5px;
    font-weight: 500;
  }
}

.procurement-total {
  padding: 0 8px;
  color: var(--dirty-purple-70);
}

// MAT PROGRESS BAR

.mat-progress-bar {
  .mat-progress-bar-buffer {
    background-color: var(--dirty-purple-8);
  }

  .mat-progress-bar-fill {
    background-color: var(--tangerine);
  }

  svg.mat-progress-bar-background {
    display: none;
  }

  &.invalid .mat-progress-bar-buffer {
    background-color: var(--watermelon);
  }
}

/* SCROLLBAR */
.vscroll {
  overflow-y: scroll;
}

/*navigator .navigator-content {
  scrollbar-color: transparent;
}*/

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: transparent;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  /*background: var(--dirty-purple);*/
  background: var(--scrollbar-thumb-hover-color);
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

html body iframe#LeadboosterContainer {
  z-index: 999 !important;
}

.cdk-drag-handle,
lxm-button.cdk-drag-handle {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/openhand.cur"),
    all-scroll;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.cdk-drag-preview {
  mat-cell {
    padding: var(--table-cell-padding);
  }
}

.cdk-drop-list-dragging {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"),
    all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lightbox {
  top: 50% !important;
  transform: translateY(-50%) !important;

  &.animation {
    animation-fill-mode: unset;
  }
}

.will-change-transform {
  will-change: transform;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

.generic-hover {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  cursor: pointer !important;
}

.list-overflow-count {
  margin-top: 2px;
  color: var(--dark-sea-green);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.show-less {
    color: var(--salmon);
  }
}
