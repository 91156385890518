.mat-table {
  display: table;
  min-width: 100%;
  width: 100%;

  .mat-header-row {
    // height: 56px;
    min-height: 56px;
    align-items: unset;
  }
}

.mat-header-cell,
.mat-cell {
  min-width: 60px;
}

.mat-header-row {
  min-width: 100%;
}

.mat-row {
  align-items: stretch;
  min-width: 100%;
}

.mat-table .mat-row {
  min-height: var(--table-row-min-height);
  border: none;
  border-bottom: solid 1px var(--dirty-purple-8);
  outline: none;
}

.mat-table .mat-header-row {
  border: none;
}

.mat-table .mat-cell {
  vertical-align: middle;
  padding: var(--table-cell-padding);
}

.mat-table .mat-header-cell {
  vertical-align: middle;
  padding: var(--table-cell-padding);
}

.mat-header-row {
  border-radius: 3px;
}

.mat-header-row:not(.clv-table-head) {
  background-color: var(--dirty-purple-8);
}

.mat-header-cell {
  font-family: RedHatDisplay;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-word;
  box-sizing: border-box;
  color: var(--dirty-purple);

  * {
    font-family: RedHatDisplay;
  }
}

.mat-cell {
  position: relative;
  font-family: RedHatText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box;
  color: var(--dirty-purple);
  word-break: break-word;
  border-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* MAT SORT  */

.mat-sort-header {
  width: 100%;

  .mat-sort-header-container {
    &:not(.mat-sort-header-sorted) {
      .mat-sort-header-arrow {
        display: none;
      }
    }
  }
}

.mat-sort-header-container {
  .mat-sort-header-content {
    text-align: inherit;
    align-items: inherit;
    justify-content: inherit;
  }
}

.mat-sort-header-arrow[style] {
  // Hide default arrow stem
  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-pointer-middle {
    background: none;
    display: none !important;
  }

  .mat-sort-header-indicator {
    opacity: 1;
    color: black;
    font-weight: bold;
    transform: none !important;
    top: 5px !important;

    &:before {
      display: flex;
      width: 12px;
      height: 12px;
      object-fit: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background: url("/assets/img/icons/sort-none.svg");
      content: "";
    }

    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
  }
}

.mat-sort-header-arrow {
  // transform: none !important;
  // transition: none !important;
  // margin-right: -12px !important; // Fix cell-end and cell-center alignment
}

[aria-sort="ascending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        position: absolute;
        display: flex;
        width: 12px;
        height: 12px;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background: url("/assets/img/icons/sort-up.svg");
        content: "";
      }
    }
  }
}

[aria-sort="descending"] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        position: absolute;
        display: flex;
        width: 12px;
        height: 12px;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background: url("/assets/img/icons/sort-down.svg");
        content: "";
      }
    }
  }
}


.mat-cell {
  .lxm-select-default {
    padding-left: 0;
  }
}