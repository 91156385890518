mat-form-field .mat-form-field-wrapper {
    padding:0 !important;
}

.mat-form-field-infix {
    border-top: 0;
    padding:0 !important;
}

.mat-form-field-label {
  display: flex !important;
  align-items: center;
  height: var(--input-min-height);
  padding: var(--input-padding);
  color: var(--placeholder-color);
  opacity: 0.8;
}

.mat-form-field .mat-icon-button {
  background: none;
}

.mat-form-field {

  &[combined] {
    font-family: RedHatText;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dirty-purple);
    border: solid 1px rgba(94, 83, 87, 0.25);
    border-radius: 3px;
    background-color: inherit;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-word;

    .mat-form-field-flex {
      align-items: center;
    }
    input {
      padding-right: 0;
    }
    .mat-icon-button {
      /*margin: 0 4px;*/
      margin: 0 6px 0 4px;
    }
    &:focus-within {
      border-color: var(--tangerine);
    }
  }

}
