/* red-hat-text-regular - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text'), local('RedHatText-Regular'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-regular.svg#RedHatText') format('svg'); /* Legacy iOS */
}
/* red-hat-text-italic - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text It'), local('RedHatText-Italic'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-italic.svg#RedHatText') format('svg'); /* Legacy iOS */
}
/* red-hat-text-500 - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text Medium'), local('RedHatText-Medium'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500.svg#RedHatText') format('svg'); /* Legacy iOS */
}
/* red-hat-text-500italic - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text Medium It'), local('RedHatText-MediumItalic'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-500italic.svg#RedHatText') format('svg'); /* Legacy iOS */
}
/* red-hat-text-700 - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text Bold'), local('RedHatText-Bold'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700.svg#RedHatText') format('svg'); /* Legacy iOS */
}
/* red-hat-text-700italic - latin */
@font-face {
  font-family: 'RedHatText';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Red Hat Text Bold It'), local('RedHatText-BoldItalic'), url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/RedHatText/red-hat-text-v2-latin-700italic.svg#RedHatText') format('svg'); /* Legacy iOS */
}
