.product-popover {
    min-width: 200px;
    max-width: 600px;
}
  
.product-popover-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: RedHatText;
    font-size: 14px;
    color: var(--dirty-purple);
    padding: 3px 0;
}

.popover-container {
    padding: 8px;
}

.popover-section {

    .popover-section-header {
      display: flex;
      flex-direction: row;
  
      .popover-section-header-text {
        font-family: RedHatText;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dirty-purple);
      }
    }
  
    .popover-section-content {
      margin-top: 8px;
  
      .popover-section-column {
        display: flex;
        flex-flow: column nowrap;
        font-family: RedHatText;
        font-size: 14px;
        color: var(--dirty-purple);
        padding: 3px 0;
  
        .popover-section-column-header {
          margin-bottom: 3px;
          font-weight: 500;
          font-family: RedHatDisplay;
        }
      }
  
      .popover-section-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-family: RedHatText;
        font-size: 14px;
        color: var(--dirty-purple);
        padding: 3px 0;
      }
    }
  
  }
  
  .dot-menu-content {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(94, 83, 87, 0.2);
  border: none !important;
  background-color: var(--white);
  font-family: RedHatText;
  font-size: 14px;
  padding: 1px 0 !important;
  z-index: 1000;

  ul {
    // box-shadow: 0 2px 8px 0 rgba(94, 83, 87, 0.2);
    margin: 0;
    padding: 5px 0;
    list-style-type: none;
    li {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      min-width: 150px;
      min-height: 30px;
      padding: 10px 20px;
      text-align: start;

      lxm-button button {
        opacity: 1 !important;
      }

      &:not(:hover) {
        > ul {
          display: none;
        }
      }

      > ul {
        position: absolute; 
        right: calc(100% + 1px);
        top: 0;
        min-width: 120px;
        background: var(--white);
        width: max-content;
        max-width: 250px;
        box-shadow: 0px 2px 8px 0px rgba(94, 83, 87, 0.2) !important;
      }

      &:hover {
        background-color: var(--dirty-purple-8);
      }
    }

  }

}
