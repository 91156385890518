:root {
  --watermelon: #f04b54;
  --dirty-purple-55: rgba(94, 83, 87, 0.55);
  --salmon: #f2736d;
  --dark-peach: #dc6e6a;
  /*--dirty-purple-8: #e6e6e6;*/
  --rusty-orange: #e06110;
  --red: #f04b54;
  --red-berry: #dc6e6a;
  --white: #ffffff;
  --dirty-purple: #5e5357;
  --dirty-purple-70: rgba(94, 83, 87, 0.7);
  --dirty-purple-8: rgba(94, 83, 87, 0.08);
  --purpley-grey: #8e8689;
  --tangerine: #f7921e;
  --tangerine-2: #f38c17;
  --lightblue: #71cff5;
  --azure: #21ade8;
  --dirty-purple-15: rgba(94, 83, 87, 0.15);
  --light-pink: #e7e5e6;
  --pale-pink: #dedbdc;
  --pale-mauve: #eeedee;
  --very-light-pink: #f3f2f2;
  --light-mauve: #a7a1a3;
  --pale-olive-green: #9ace81;
  --dark-peal-olive-green: #769F62;
  --peal-olive-gren: #769F62;
  --dark-sea-green: #7ABF8E;
  --green-warm-chinise-white: #E2F0DA;
  --green-warm-lime: #C6E4B8;
  --viridian: #41A15D;
  --lime: #C6E4B8;
  --chinise-white: #E2F0DA;
  --frugal: #BFE5CA;
  --ice: #DEF2E6;
  --frosted-mint: #ECF7F0;
  --pale-purple: #c490d1;
  --color-1: #fcdf76;
  --color-2: #9ace81;
  --color-3: #71cff5;
  --color-4: #6f9ceb;
  --color-5: #c490d1;
  --color-6: #b89494;
  --color-7: #f2736d;
  --color-8: #944b56;
  --color-9: #5e5357;
  --color-10: #ffffff;

  --rosey-afterglow: #fbc9cc;
  --berry: #dc6e6a;
  --tangerine: #f7921e;
  --peach: #fbaf57;
  --light-peach: #fcdebb;
  --very-light-peach: #feefdd;
  --grey-20: #e7e5e6;
  --grey-100: #5e5357;
  --grey-70: #8e8789;
  --grey-60: #a6a0a3;
  --grey-40: #d8d6d7;
  --grey-30: #dedbdc;
  --grey-50: #d5d2d2;
  --grey-10: #efeeee;
  --grey-alt: #f3f2f2;
  --grey-alt2: rgba(94, 83, 87, 0.7);
  --light-blue: #71cff5;
  --viridian: #22974b;
  --ice: #def2e6;
  --off-white: #f0f8ec;
  --light-maroon: #944b56;
  --golden: #e7af00;
  --blue: #4f57b1;
  --valerie: #fde4e6;
  --retailers-a1000: #e2232d;
  --retailers-coop: #007bd9;
  --retailers-delice: #741434;
  --retailers-grossi: #e80d02;
  --retailers-maxima: #004f9e;
  --retailers-prisma: #00a650;
  --retailers-r-kiosk: #555759;
  --retailers-rimi: #ed1c24;
  --retailers-selver: #f01319;
  --retailers-stockmann: #181c20;
  --very-light-alice-blue: #e8f8ff;
  --peal-olive-green: #9ace81;
  --light-alice-blue: #d4f1fc;
  --frugal: #a3dab6;
  --lime: #d4ebcf;
  --medium-alice-blue: #bdecff;
  --medium-light-peach: #ffd3a3;

  --peach-gradient: linear-gradient(169deg, #fbd7cf -46.75%, #fde4c7 105.69%);
  --dark-peach-gradient: linear-gradient(
    169deg,
    #fac0b4 -46.75%,
    #fcd3a5 105.69%
  );
  --green-gradient: linear-gradient(151deg, #c5e8d2 4.38%, #e1f1dd 116.79%);
  --tangerine-gradient: linear-gradient(
    169deg,
    #f04b54 -46.75%,
    #f7921e 105.69%
  );
  --darker-tangerine-gradient: linear-gradient(
    169deg,
    #f7921e -46.75%,
    #f04b54 105.69%
  );
  --darker-green-gradient: linear-gradient(
    151deg,
    #a3dab6 4.38%,
    #d4ebcf 116.79%
  );
  --orange-gradient: linear-gradient(169deg, #f04b54 -46.75%, #f7921e 105.69%);
}

.tangerine {
  color: var(--tangerine) !important;
}

.color--red {
  color: var(--red);
}

.color--tangerine {
  color: var(--tangerine);
}

.color--watermelon {
  color: var(--watermelon);
}

.color--azure {
  color: var(--azure);
}
