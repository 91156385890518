.mat-paginator-range-actions {
    button {
        background: none;

        .mat-paginator-icon {
            /*stroke: var(--dirty-purple);
            fill: var(--dirty-purple);*/
        }
    }
}

.mat-paginator-page-size {

}

.mat-paginator-page-size-select {
  /*width: 64px !important;*/
  margin: 0 6px !important;
}

.mat-paginator-range-label {
    margin-left: 0 !important;
}
